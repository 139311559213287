.Admin {
	.buttons_parent {
		display: flex;

		.admin-button {
			margin: 0px 5px;
			flex: 1;
			cursor: pointer;
			background-color: #8d1737;
			color: white;
			outline: none;
			border: 1px solid transparent;
			transition: 0.2s ease-in-out;
		}

		.admin-button:hover {
			border: 1px solid #8d1737;
			background-color: white;
			color: #8d1737;
		}
	}

	.modal-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.image-file {
			width: 150px;
			height: 150px;
			border: 2px solid black;
			margin: 10px;
			margin-top: 0;
			border-radius: 10px;
			cursor: pointer;
			transition: 0.2s ease-in-out;
		}

		.image-file.clicked {
			transition: none;
			position: absolute;
			top: 10px;
			left: 10px;
			width: calc(100% - 20px);
			margin: 0px;
			box-sizing: border-box;
			height: fit-content;
			z-index: 999;
		}

		.image-file.clicked:hover {
			transform: none;
		}

		.image-file:hover {
			transform: scale(1.05);
		}

		.buttons-action {
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.action-button {
				margin: 0px 5px;
				width: 200px;
				cursor: pointer;
				background-color: #8d1737;
				color: white;
				outline: none;
				border: 1px solid transparent;
				transition: 0.2s ease-in-out;
			}

			.action-button:hover {
				border: 1px solid #8d1737;
				background-color: white;
				color: #8d1737;
			}
		}

		.conversation-wrapper {
			flex: 1;
			overflow: auto;
			padding: 15px 10px;

			.no-questions {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-weight: 600;
				box-sizing: border-box;
				margin: 0;
				height: 100%;
			}

			.conv-question {
				border: 1px solid #e8e8e8;
				margin: 10px 0px;
				padding: 10px;
				border-radius: 5px;
				.conv-content-quest {
					margin: 0;
					font-weight: bold;
					font-size: 18px;
				}

				.conv-content-answer {
					margin: 0;
				}

				.question-date {
					display: block;
					font-size: 12px;
					color: gray;
					direction: rtl;
				}

				.question-buttons {
					direction: rtl;
					display: flex;
					margin-bottom: 10px;

					.question-button {
						margin: 0px 5px;
						height: fit-content;
						padding: 5px 30px;
						background-color: #8d1737;
						color: white;
						outline: none;
						border: 1px solid transparent;
						transition: 0.2s ease-in-out;
						border-radius: 5px;
						cursor: pointer;
					}

					.question-button:hover {
						border: 1px solid #8d1737;
						background-color: white;
						color: #8d1737;
					}
				}
			}
		}

		.question-wrapper {
			margin: 15px;
			padding-top: 15px;
			border-top: 2px solid #c4c4c4;

			.question-label {
				margin: 0;
				font-weight: bold;
			}

			.question-form-wrapper {
				display: flex;

				.question-textarea {
					width: 100%;
					resize: none;
					box-sizing: border-box;
					height: 100px;
					outline: none;
					border: 1px solid #c4c4c4;
					border-radius: 5px;
					padding: 5px;
				}

				.question-button {
					margin: 0px 10px;
					height: fit-content;
					padding: 5px 30px;
					background-color: #8d1737;
					color: white;
					outline: none;
					border: 1px solid transparent;
					transition: 0.2s ease-in-out;
					border-radius: 5px;
					cursor: pointer;
				}

				.question-button:hover {
					border: 1px solid #8d1737;
					background-color: white;
					color: #8d1737;
				}
			}
		}
	}
}

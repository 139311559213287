.top-header {
	position: sticky;
	top: 0;
	background: #fff;
	box-shadow: 0 3px 30px #00000029;
	z-index: 999;

	.top-header-bar {
		display: flex;
		align-items: center;
		height: 40px;
		background-color: #8d1737;
		padding: 0 5%;
	}

	.top-header-logos {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 5%;

		.search-wrapper {
			display: flex;
			align-items: center;
			height: 50px;
			max-width: 150px;

			.header-search-icon {
				padding: 0 10px;
				background: #eaeaea;
				color: #7a7b7e;
				height: 100%;
				cursor: pointer;
			}

			.header-search-input {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				outline: none;
				border: none;
				direction: rtl;
				padding: 8px 15px;
				color: #7a7b7e;
				background: #f4f4f4;
				font-family: "Cairo", sans-serif !important;
			}
		}

		.header-logos {
			display: flex;
			gap: 30px;

			.header-logo {
				position: relative;
				display: flex;
				align-items: center;
				max-height: 100%;

				img {
					width: 100%;
					height: auto;
					max-height: 74.77px;
				}

				&:last-child {
					&::after {
						position: absolute;
						background-color: transparent;
						width: 1px;
						height: 38px !important;
						content: "";
						inset-inline-end: -15px;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				&::after {
					position: absolute;
					background-color: #7c0c26;
					width: 1px;
					height: 38px !important;
					content: "";
					inset-inline-end: -15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.header-navigations-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f4f4f4;
		height: 60px;

		.mobile-menu-btn {
			display: none;
		}

		.header-navigations {
			display: flex;
			direction: rtl;

			.header-navigation-wrapper {
				position: relative;

				.header-navigation {
					background-color: transparent;
					border: none;
					outline: none;
					cursor: pointer;
					font-family: "Cairo", sans-serif !important;
					font-size: 0.875rem;
					padding: 0.5rem;
					color: rgba(0, 0, 0, 0.5);
					font-weight: 600;

					.arrow-down-icon-wrapper {
						display: none;
					}

					&.dropdown {
						&::after {
							display: inline-block;
							width: 0;
							height: 0;
							margin-left: 0.255em;
							vertical-align: 0.255em;
							content: "";
							border-top: 0.3em solid;
							border-right: 0.3em solid transparent;
							border-bottom: 0;
							border-left: 0.3em solid transparent;
						}
					}
				}

				&:hover {
					.header-navigation-dd {
						max-height: 100vh;
						border: 1px solid rgba(0, 0, 0, 0.15);
						overflow: visible;
					}
				}

				.header-navigation-dd {
					position: absolute;
					background-color: #fff;
					border: 0px solid rgba(0, 0, 0, 0.15);
					z-index: 1000;
					width: 200px;
					left: 0;
					max-height: 0px;
					transition: 0.5s ease-in-out;
					overflow: hidden;

					.headeroption-navigation-wrapper {
						position: relative;

						.header-navigation-option {
							align-items: center;
							justify-content: center;
							align-items: center;
							width: 100%;
							min-height: 45px;
							padding: 10px;
							outline: none;
							cursor: pointer;
							border: none;
							background-color: transparent;
							font-family: "Cairo", sans-serif !important;
							transition: 0.2s ease-in-out;

							.option-options-arrow {
								float: left;
							}

							&:hover {
								background-color: #e9e8e8;
							}
						}

						&:hover {
							.header-navigation-option-dd {
								max-height: 100vh;
								border: 1px solid rgba(0, 0, 0, 0.15);
							}
						}

						.header-navigation-option-dd {
							position: absolute;
							background-color: #fff;
							border: 0px solid rgba(0, 0, 0, 0.15);
							z-index: 1001;
							width: 200px;
							top: 0;
							left: -204px;
							max-height: 0px;
							transition: 0.5s ease-in-out;
							overflow: hidden;

							.headeroption-navigation-option-wrapper {
								position: relative;

								.header-navigation-option-option {
									display: flex;
									align-items: center;
									justify-content: center;
									align-items: center;
									padding: 10px;
									width: 100%;
									min-height: 45px;
									outline: none;
									cursor: pointer;
									border: none;
									background-color: transparent;
									font-family: "Cairo", sans-serif !important;
									transition: 0.2s ease-in-out;

									&:hover {
										background-color: #e9e8e8;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1030px) {
	.top-header {
		.top-header-logos {
			.search-wrapper {
				display: none;
			}
		}

		.header-navigations-wrapper {
			position: relative;
			justify-content: flex-start;
			padding: 0 10px;

			.mobile-menu-btn {
				display: block;
				font-size: 30px;
				color: rgba(0, 0, 0, 0.5);
				border: 1px solid transparent;
				border-color: rgba(0, 0, 0, 0.1);
				padding: 0.25rem 0.75rem;
				border-radius: 0.25rem;
			}

			.header-navigations {
				position: absolute;
				background-color: #fff;
				width: calc(100% - 20px);
				flex-direction: column;
				top: 80px;
				max-height: calc(100vh - 250px);
				overflow: auto;

				.header-navigation-wrapper {
					.header-navigation {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;

						&::after {
							display: none !important;
						}

						.arrow-down-icon-wrapper {
							display: block;
							background-color: transparent;
							border: 1px solid #ccc;

							.arrow-down-icon {
								background-color: transparent;
								margin: 0;
								padding: 0;
							}
						}
					}

					.header-navigation-dd {
						position: relative;
						border: none !important;
						width: calc(100% - 50px);
						margin: 0 25px;

						.headeroption-navigation-wrapper {
							.header-navigation-option {
								display: flex;
								justify-content: space-between;

								.option-options-arrow {
									border: 1px solid #ccc;
									padding: 5px;
									transform: rotate(-90deg);
								}
							}

							.header-navigation-option-dd {
								position: relative;
								border: none !important;
								width: calc(100% - 50px);
								margin: 0 25px;
								left: 0;

								.headeroption-navigation-option-wrapper {
									.header-navigation-option-option {
										justify-content: flex-start;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

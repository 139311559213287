.page-content {
	.home-top-section {
		background-image: url("../../images/index-banner.jpg");
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		width: 100%;
		height: 100vh;
		padding: 0;
		position: relative;

		.intro-text {
			position: absolute;
			top: 220px;
			right: 50%;
			transform: translateX(50%);
			z-index: 100;
			color: #fff;
			font-size: 2.5rem;
		}
	}

	.latest_updates-section {
		position: relative;
		margin-top: -124px;

		.latest-updates {
			display: flex;
			max-width: 1140px;
			margin: auto;
			gap: 10px;
			flex-direction: row-reverse;

			.latest-update {
				background-color: #fff;
				flex: 1;
				overflow: hidden;
				border-top: 8px solid transparent;
				margin-bottom: 30px;
				box-shadow: -1px 3px 13px #dfdfdf;

				.latest-update-header {
					display: flex;
					gap: 10px;
					padding: 10px;

					.latest-update-icon {
						background: url("../../images/sprite.png") no-repeat;
						display: inline-block;
					}

					.latest-update-header-content {
						direction: rtl;
						flex: 1;

						.latest-update-title {
							margin: 0;
							color: #000;
							font-size: 1.0625rem;
							text-decoration: none;
						}

						.latest-update-value {
							margin: 0;
							margin: 5px 0 0;
							color: #000;
							font-size: 0.8125rem;
							padding: 0;
							display: block;
							display: -webkit-box;
							max-width: 100%;
							height: 54px;
							line-height: 18px;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							text-decoration: none;
						}

						.latest-update-date {
							display: flex;
							align-items: center;
							margin: 0;
							float: right;
							font-size: 0.8125rem;
						}
					}
				}
				.latest-update-image-wrapper {
					position: relative;
					max-width: 100%;
					height: 255px;
					border-top: 1px solid transparent;
					cursor: pointer;
					overflow: hidden;

					.latest-update-image {
						position: absolute;
						top: 0px;
						right: 0px;
						width: 100%;
						height: 100%;
						z-index: 3;
						object-fit: cover;
						object-position: top;
						transition: 0.2s ease-in-out;
					}

					.latest-update-readmore {
						position: absolute;
						bottom: 0;
						right: 0;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #000;
						background: red;
						height: 45px;
						text-transform: none;
						opacity: 0;
						transition: 0.2s ease-in-out;
					}

					&:hover {
						.latest-update-image {
							top: -45px;
						}

						.latest-update-readmore {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.anim_reveal-section {
		background-color: #8d0134;
		direction: rtl;
		padding: 40px 0;

		.anim_reveal-content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: auto;
			max-width: 1140px;
			position: relative;

			.anim_reveal-title {
				border-bottom: 2px solid #fff;
				position: relative;
				font-size: 2.25rem;
				font-weight: bold;
				display: inline-block;
				padding-bottom: 10px;
				color: #fff;

				&::after {
					width: auto;
					right: 0;
					left: -50px;
					background: #fff;
					bottom: -3px;
					content: "";
					position: absolute;
					height: 1px;
				}
			}

			.anim_reveal-content-btn-wrapper {
				display: flex;
				align-items: center;

				.anim_reveal-desc {
					font-size: 1.25rem;
					padding: 0 0 0 25px;
					color: #fff;
				}

				.anim_reveal-btn {
					cursor: pointer;
					color: #8d0134;
					font-family: "Cairo", sans-serif;
					font-weight: 700;
					padding: 5px 15px;
					border-radius: 0;
					background: #fff;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					user-select: none;
					font-size: 1rem;
					line-height: 1.5;
					text-decoration: none;
					outline: none;
					transition: 0.35s ease-in-out;

					&:hover {
						opacity: 0.7;
					}
				}
			}

			.anim_reveal-img {
			}
		}
	}

	.bss-services-section {
		background-color: #fff;
		direction: rtl;
		padding: 40px 0;

		.bss-services-content {
			display: flex;
			max-width: 1140px;
			margin: auto;
			position: relative;

			.bss-service {
				flex: 1;
				overflow: hidden;
				padding: 0 35px;
				text-decoration: none;
				color: #000;

				&:first-child {
					padding-right: 0;
				}

				&:last-child {
					padding-left: 0;
				}

				&:hover {
					.bss-service-img {
						&.trade {
							background-image: url("../../images/trade-hover.png");
						}

						&.business {
							background-image: url("../../images/business-hover.png");
						}

						&.tejara {
							background-position: -137px -313px;
						}

						&.consumer {
							background-position: 0px -311px;
							width: 110px;
						}
					}

					.bss-service-title {
						color: #8d0134;
					}

					.bss-service-value {
						color: #8d0134;
					}
				}

				.bss-service-img {
					background-position: center;
					background-repeat: no-repeat;
					width: 100%;
					height: 180px;
					position: relative;
					text-align: center;
					display: flex;
					justify-content: center;

					&.trade {
						background-image: url("../../images/trade.png");
					}

					&.business {
						background-image: url("../../images/business.png");
					}

					&.tejara {
						background: url("../../images/sprite.png") no-repeat;
						background-position: -137px -181px;
						width: 127px;
						height: 124px;

						&::after {
							display: none;
						}
					}

					&.consumer {
						background: url("../../images/sprite.png") no-repeat;
						background-position: 0px -179px;
						width: 110px;
						height: 107px;

						&::after {
							display: none;
						}
					}

					&::after {
						content: "";
						position: absolute;
						bottom: 0px;
						left: 50%;
						transform: translateX(-50%);
						width: 90px;
						height: 1px;
						background: #bcbec0;
					}
				}

				.bss-service-title {
					clear: both;
					line-height: 1.4;
					margin: 0 0 0.75em;
					padding: 1em 0 0;
					color: #494949;
					font-weight: 300;
					font-size: 1.5625rem;
					padding-top: 10px;
					text-align: center;
				}

				.bss-service-value {
					margin: 0 0 1em;
					padding: 0;
					color: #222;
					text-align: center;
				}

				.bss-service-main-title {
					line-height: 1.4;
					margin: 0 0 0.75em;
					padding: 1em 0 0;
					color: #494949;
					border-bottom: 2px solid #7d7d7d;
					position: relative;
					font-weight: bold;
					display: inline-block;
					padding-bottom: 10px;
					padding-top: 0;
					font-size: 2.25rem;

					&::after {
						content: "";
						position: absolute;
						width: auto;
						right: 0;
						bottom: -3px;
						left: -50px;
						height: 1px;
						background: #7d7d7d;
					}
				}

				.bss-service-main-value {
					margin: 0 0 1em;
					padding: 0;
				}
			}
		}
	}

	.thousand-banner-section {
		background-image: url("../../images/thousand_bg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		color: white;
		direction: rtl;
		padding: 40px 0;

		.thousand-banner-content {
			max-width: 1140px;
			display: flex;
			align-items: center;
			margin: auto;
			gap: 40px;

			.thousand-banner-right {
				flex: 1;
				text-align: left;

				.thousand-banner-img {
					height: 150px;
					max-width: auto;
					flex: 1;
				}
			}

			.thousand-banner-left {
				flex: 1;

				.thousand-banner-desc {
					margin: 0 0 1em;
					padding: 0;
				}

				.thousand-banner-btn {
					display: flex;
					align-items: center;
					width: fit-content;
					gap: 30px;
					color: #9e3452;
					padding: 5px 10px;
					border-radius: 5px;
					background-color: #fff;
					font-weight: bold;
					box-shadow: 3px 3px 4px 2px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
					text-decoration: none;
					outline: none;
					transition: all 0.2s;
				}
			}
		}
	}

	.online_services-section {
		background-color: #fff;
		direction: rtl;
		padding: 40px 0;

		.online_services-content {
			display: flex;
			max-width: 1140px;
			justify-content: space-between;
			margin: auto;
			position: relative;

			.online_service {
				.online_service-main-title {
					line-height: 1.4;
					margin: 0 0 0.75em;
					padding: 1em 0 0;
					color: #50bbe7;
					border-bottom: 2px solid #50bbe7;
					position: relative;
					font-weight: bold;
					display: inline-block;
					padding-bottom: 10px;
					padding-top: 0;
					font-size: 2.25rem;

					&::after {
						content: "";
						position: absolute;
						width: auto;
						right: 0;
						bottom: -3px;
						left: -50px;
						height: 1px;
						background: #50bbe7;
					}
				}

				.online_service-main-value {
					margin: 0 0 1em;
					padding: 0;
				}

				.online_service-btn {
					color: white;
					background: #50bbe7 !important;
					padding: 5px 15px;
					border-radius: 0;
					font-family: "Cairo", sans-serif;
					font-weight: 700;
					cursor: pointer;
					text-align: center;
					white-space: nowrap;
					vertical-align: middle;
					font-size: 1rem;
					line-height: 1.5;
					user-select: none;
					text-decoration: none;
					outline: none;
				}
			}
		}
	}

	.facts_and_figures-section {
		background-image: url("../../images//facts_and_figures_bg.jpg");
		background-repeat: no-repeat;
		background-position: right center;
		background-size: contain;
		background-attachment: fixed;
		background-color: #ffe054;
		padding: 150px 0;
		direction: rtl;

		.facts_and_figures-content {
			display: flex;
			max-width: 1140px;
			justify-content: space-between;
			margin: auto;
			position: relative;

			.facts_and_figures-scroll-container {
				display: flex;
				align-items: center;
				flex: 1;
				justify-content: center;
				gap: 10px;
				max-width: 570px;
				overflow: hidden;

				.arrow-icon {
					font-size: 40px;
					opacity: 0.6;
					cursor: pointer;
					transition: 0.2s ease-in-out;

					&:hover {
						opacity: 1;
					}
				}

				.facts_and_figures-scroll-child {
					width: 400px;
					max-width: 100%;
					overflow: hidden;
					padding: 30px;

					.child-num-wrapper {
						display: flex;
						justify-content: center;
						direction: ltr;

						.child-num {
							font-size: 4.6875rem;
							color: #000;
							font-weight: bold;
						}

						.child-snum {
							font-size: 3.4875rem;
							color: #000;
							font-weight: bold;
							margin-top: -0.5em;
						}
					}

					.child-mid-title {
						font-size: 1.625rem;
						margin-right: 6px px;
						display: block;
						text-align: center;
					}

					.child-maroon {
						color: #8c1738 !important;
						font-weight: 700 !important;
						font-size: 1.1875rem;
						clear: both;
						line-height: 1.4;
						margin: 0 0 0.75em;
						padding: 1em 0 0;
						text-align: center;
					}

					.child-desc {
						text-align: center;
					}
				}
			}

			.facts_and_figures {
				flex: 1;
				.facts_and_figures-main-title {
					line-height: 1.4;
					margin: 0 0 0.75em;
					padding: 1em 0 0;
					color: #50bbe7;
					border-bottom: 2px solid #50bbe7;
					position: relative;
					font-weight: bold;
					display: inline-block;
					padding-bottom: 10px;
					padding-top: 0;
					font-size: 2.25rem;

					&::after {
						content: "";
						position: absolute;
						width: auto;
						right: 0;
						bottom: -3px;
						left: -50px;
						height: 1px;
						background: #50bbe7;
					}
				}

				.facts_and_figures-main-value {
					margin: 0 0 1em;
					padding: 0;
				}
			}
		}
	}

	.media_center-section {
		background-color: #fff;
		direction: rtl;
		padding: 40px 0;

		.media_center-content {
			max-width: 1140px;
			margin: auto;
			position: relative;

			.media_center {
				.media_center-main-title {
					line-height: 1.4;
					margin: 0 0 0.75em;
					padding: 1em 0 0;
					color: #8c1738;
					border-bottom: 2px solid #8c1738;
					position: relative;
					font-weight: bold;
					display: inline-block;
					padding-bottom: 10px;
					padding-top: 0;
					font-size: 2.25rem;

					&::after {
						content: "";
						position: absolute;
						width: auto;
						right: 0;
						bottom: -3px;
						left: -50px;
						height: 1px;
						background: #8c1738;
					}
				}
			}

			.media_center-images {
				display: flex;
				flex-wrap: wrap;
				overflow: hidden;
				gap: 10px;

				.document-icon {
					height: fit-content;
				}

				.media_center-image-wrapper {
					height: 180px;
					width: 275px;

					.media_center-image {
						height: 180px;
						width: 275px;
						object-fit: cover;
						object-position: top;
					}
				}
			}
		}
	}

	.our-loaction-section {
		padding: 40px 0;
		.our-location {
			width: 100%;
		}

		.our-location-mobile {
			display: none;
		}
	}
}

@media only screen and (max-width: 768px) {
	.page-content {
		.home-top-section {
			.intro-text {
				top: 20px;
				font-size: 2.1875rem;
				width: 80%;
				text-align: center;
			}
		}

		.latest_updates-section {
			margin-left: 10px;
			margin-right: 10px;

			.latest-updates {
				flex-direction: column;
			}
		}

		.anim_reveal-section {
			.anim_reveal-content {
				margin-left: 10px;
				margin-right: 10px;
				flex-direction: column;
			}
		}
		.bss-services-section {
			.bss-services-content {
				margin-left: 10px;
				margin-right: 10px;
				flex-direction: column;

				.bss-service {
					padding: 10px 35px;
				}
			}
		}

		.thousand-banner-section {
			.thousand-banner-content {
				margin-left: 10px;
				margin-right: 10px;
				flex-direction: column;

				.thousand-banner-right {
					.thousand-banner-img {
						max-width: 100%;
						flex: 1;
					}
				}
			}
		}

		.online_services-section {
			.online_services-content {
				margin-left: 10px;
				margin-right: 10px;
				flex-direction: column;
			}
		}

		.facts_and_figures-section {
			.facts_and_figures-content {
				margin-left: 10px;
				margin-right: 10px;
				flex-direction: column;
			}
		}

		.media_center-section {
			.media_center-content {
				margin-left: 10px;
				margin-right: 10px;
				.media_center-images {
					.media_center-image-wrapper {
						max-width: 40%;
						box-sizing: border-box;
						.media_center-image {
							max-width: 100%;
							height: 150px;
						}
					}
				}
			}
		}

		.our-loaction-section {
			padding: 40px 0;
			.our-location {
				display: none;
			}

			.our-location-mobile {
				width: 100%;
				display: block;
			}
		}
	}
}

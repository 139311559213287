.bottom-nav {
	.bottom-nav-content {
		display: flex;
		max-width: 1140px;
		margin: auto;
		padding: 20px 0;
		justify-content: space-around;
		gap: 50px;

		.bottom-nav-content-left {
			max-width: 350px;
			direction: rtl;
			margin: auto;

			.bottom-subscribtion-desc {
				margin-bottom: 10px;
				font-size: 0.9375rem;
				line-height: 25px;
			}

			.bottom-subscribtion-wrapper {
				display: flex;
				align-items: center;
				gap: 10px;

				.bottom-subscribtion-input {
					background: #eaeaea;
					border: none;
					color: #333;
					width: calc(100%);
					display: inline-block;
					text-indent: 2%;
					border-radius: 3px;
					height: 40px !important;
					font-family: "Cairo", sans-serif !important;
					font-weight: normal;
					text-align: right;
					font-size: 1rem;
					line-height: 1.66;
					max-width: 100%;
				}

				.bottom-subscribtion-btn {
					background-color: #8c1738;
					color: #fff;
					font-size: 0.875rem;
					font-weight: 800;
					line-height: 1;
					border-radius: 3px;
					user-select: none;
					height: 40px !important;
					outline: none;
					padding: 0 20px;
					font-family: "Cairo", sans-serif !important;
					border: none;
					cursor: pointer;
					transition: 0.2s ease-in-out;
					direction: ltr;

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}

		.bottom-nav-content-right {
			direction: rtl;

			.bottom-nav-btn {
				display: flex;
				align-items: center;
				gap: 10px;
				font-size: 0.875rem;
				color: #222;
				text-decoration: none;
				outline: none;
				transition: all 0.2s;

				&:hover {
					color: #8c1738;
				}

				.bottom-btn-icon {
					font-size: 22px;
				}
			}

			.bottom-navigations {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 20px;

				.bottom-nav-btn {
					margin: 0 10px;
				}
			}

			.bottom-navigations-tel {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 20px;
				column-gap: 20px;

				.bottom-btn-icon {
					font-size: 22px;
					color: #333;
					transition: 0.2s ease-in-out;

					&:hover {
						color: #8c1738;
					}
				}

				.tel-service {
					color: #333;
					font-family: "Cairo", sans-serif;
					font-weight: 500;
					font-size: 1rem;
				}

				.download-app {
					display: flex;
					align-items: center;
					gap: 10px;

					.download-app-title {
						color: #333;
						font-family: "Cairo", sans-serif;
						font-weight: 500;
						font-size: 1rem;
					}
				}
			}
		}
	}

	.website-rights {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		box-sizing: border-box;
		direction: rtl;
		height: 120px;
		background-color: #fff;
		max-width: 1140px;
		margin: auto;
		padding: 20px 0;
		justify-content: space-around;

		.rights-image {
			height: 100%;
		}

		.rights-value {
			padding: 0px 30px;
			font-size: 0.875rem;
			color: #000;
			font-weight: 300;

			.rights-href {
				margin: 0px 10px;
			}

			.rights-link,
			.rights-href {
				color: inherit;
				text-decoration: none;
			}

			.rights-link:hover,
			.rights-href:hover {
				text-decoration: underline;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.bottom-nav {
		margin-left: 10px;
		margin-right: 10px;
		.bottom-nav-content {
			flex-direction: column-reverse;
		}

		.website-rights {
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;

			.rights-value {
				width: 100%;
				text-align: center;
				margin: 5px 0px;
			}
		}
	}
}

.page-content {
	margin: 0 auto;
	flex: 1;

	.breadcrumbs-style {
		direction: rtl;
		background-color: transparent;
		background-color: transparent;
		margin: 10px 5px;

		.MuiTypography-root {
			font-weight: bold;
			font-family: inherit;
			font-size: 16px;
		}
	}

	.register-form {
		width: 90%;
		max-width: 1200px;
		background-color: #fff;
		margin: 50px auto;
		box-sizing: border-box;
		padding: 30px;
		border: 2px solid #8d1737;
		box-shadow: 0 -5px 0 0 rgb(147 149 152 / 20%);
		min-height: 440px;

		.form-title {
			color: #8d1737;
			text-align: center;
			font-weight: 300;
			font-size: 2.5rem;
			margin-top: 0px;
			margin-bottom: 0.5rem;
		}

		.form-title-small-content {
			text-align: center;
			direction: rtl;
		}

		.form-title-small {
			color: black;
			font-weight: 300;
			font-size: 1.25rem;
			margin-top: 0px;
			margin-bottom: 0.5rem;
			margin: auto 5px;
		}

		.alert-message {
			color: red;
			text-align: center;
		}

		.row {
			direction: rtl;
			margin-bottom: 20px;

			.row-title {
				margin-bottom: 0;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 300;
				color: #8d1737;
			}

			.row-title[mandatory="true"]::after {
				content: "*";
				color: red;
			}

			.row-input {
				width: 100%;
				border: 2px solid #d7d7d7;
				box-shadow: inset 0px 1px 5px 0 rgb(0 0 0 / 10%);
				height: 43px;
				line-height: 43px;
				font-size: 1rem;
				padding: 0 10px;
				box-sizing: border-box;
				color: #495057;
				background-color: #fff;
				border-radius: 3px;
				transition: all 0.3s cubic-bezier(0.33, 0.07, 0.23, 0.86);
				outline: none;
			}

			.row-input.error {
				border: 2px solid red;
			}

			.row-input:disabled {
				background-color: #e9ecef;
			}

			.row-input:focus {
				border: 2px solid #8d1737;
			}
		}

		.online-service {
			direction: rtl;
			color: #8d1737;
			cursor: pointer;
			width: fit-content;
			margin: auto 0 auto auto;
			display: block;
			user-select: none;
		}

		.form-buttons {
			display: block;
			margin: 0px auto;
			text-align: center;
			margin-top: 50px;

			.form-submit-btn {
				background: linear-gradient(-232.56deg, var(--c2, --c2), var(--c1, --c1) 51%, var(--c2, --c2)) var(--x, 0) / 200%;
				--c1: #8d1737;
				--c2: #0c0a37;
				color: #fff;
				font-size: 14px;
				line-height: 1.5;
				padding: 0.5rem 1.6rem;
				border: none;
				cursor: pointer;
				box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
				border-radius: 0.125rem;
				outline: none;
				margin: 0px 10px;
			}

			.form-submit-btn:hover {
				--c1: #0c0a37;
				--c2: #8d1737;
			}
		}
	}

	.modal-bg {
		position: fixed;
		z-index: 1000;
		width: 100%;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);

		.modal {
			background-color: #fff;
			width: 700px;
			max-width: 90%;
			margin: 25px auto;
			box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
			border-radius: 0.125rem;
			opacity: 1;
			animation: ModalSlide 0.5s ease-in-out;
			margin-top: 25px;

			@keyframes ModalSlide {
				from {
					margin-top: -100vh;
					opacity: 0;
				}
				to {
					margin-top: 25px;
					opacity: 1;
				}
			}

			.modal-header {
				background: #8d1737;
				background-image: -webkit-linear-gradient(232.56deg, #8d1737 0%, #0c0a37 100%);
				background-image: linear-gradient(232.56deg, #8d1737 0%, #0c0a37 100%);
				padding: 16px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				direction: rtl;
				align-items: center;

				.modal-title {
					color: #fff;
					font-weight: 300;
					font-size: 1.25rem;
					direction: rtl;
					padding: 0;
					margin: 0;
				}

				.close-button {
					border: none;
					outline: none;
					background-color: transparent;
					width: fit-content;
					height: fit-content;
					margin: 0;
					padding: 0;
					cursor: pointer;
					text-shadow: 0 1px 0 #fff;
					color: #fff;
					opacity: 0.5;
					font-size: 1.5rem;
					font-weight: 700;
					line-height: 1;
				}

				.close-button:hover {
					opacity: 0.7;
				}
			}

			.modal-body {
				background-color: #fff;

				.login-form {
					width: 90%;
					margin: 0 auto;
					padding-bottom: 30px;

					.row {
						direction: rtl;
						margin-bottom: 20px;

						.row-title {
							margin-bottom: 0;
							box-sizing: border-box;
							font-size: 16px;
							font-weight: 300;
							color: #212529;
						}

						.row-title[mandatory="true"]::after {
							content: "*";
							color: red;
						}

						.row-input {
							width: 100%;
							border: 2px solid #d7d7d7;
							box-shadow: inset 0px 1px 5px 0 rgb(0 0 0 / 10%);
							height: 43px;
							line-height: 43px;
							font-size: 1rem;
							padding: 0 10px;
							box-sizing: border-box;
							color: #495057;
							background-color: #fff;
							border-radius: 3px;
							transition: all 0.3s cubic-bezier(0.33, 0.07, 0.23, 0.86);
							outline: none;
						}

						.row-input:disabled {
							background-color: #e9ecef;
						}

						.row-input:focus {
							border: 2px solid #0e8a8a;
						}
					}

					.form-buttons {
						display: block;
						margin: 0px auto;
						margin-top: 20px;

						.form-submit-btn {
							background: linear-gradient(-232.56deg, var(--c2, --c2), var(--c1, --c1) 51%, var(--c2, --c2)) var(--x, 0) / 200%;
							--c1: #8d1737;
							--c2: #0c0a37;
							color: #fff;
							font-size: 14px;
							line-height: 1.5;
							padding: 0.5rem 1.6rem;
							border: none;
							cursor: pointer;
							box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
							border-radius: 0.125rem;
							outline: none;
							margin: 0px 10px;
						}

						.form-submit-btn:hover {
							--c1: #0c0a37;
							--c2: #8d1737;
						}
					}
				}
			}
		}
	}

	.usernamePasswordErrorMsg {
		color: red;
		font-size: 14px;
		text-align: center;
	}
}

input,
button,
textarea,
select {
	font-family: "DroidArabicKufiRegular", "DroidSans", sans-serif;
}

.input-text,
.input-textarea,
.input-select {
	display: block;
	width: calc(100% - 19px);
	height: 52px;
	margin-bottom: 15px;
	color: #555;
	border: 1px solid #f1f1f1;
	background-color: #f9f9f9;
	font-size: 16px;
	border-radius: 5px 5px 5px 5px;
	outline: none;
	padding-right: 15px;
	-webkit-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

.input-select {
	width: 100%;
}

.input-textarea {
	height: auto;
	resize: none;
}

.input-text:focus,
.input-textarea:focus,
.input-select:focus {
	border: 1px solid grey;
}

.form-button {
	background: #e52f37;
	border: none;
	color: #fff;
	letter-spacing: 1px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	width: 100%;
	padding: 16px 0;
	border-radius: 3px;
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.form-button:hover {
	background: #8d1737;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

button:disabled {
	background-color: white;
	border: 2px solid #e52f37;
	color: #e52f37;
}

button:disabled:hover {
	background-color: white;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Cairo", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
}

code {
	font-family: "Cairo", sans-serif !important;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

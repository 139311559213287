.register-card {
	.pagination_steps {
		display: flex;
		width: fit-content;
		margin: auto;
		justify-content: space-between;
		position: relative;
		padding: 30px 0;
		direction: rtl;

		.pagination_step {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			.step-name {
				margin: 0;
				margin-bottom: 15px;
				padding: 0px 15px;
				font-size: 14px;
				font-weight: 600;
			}

			.step-number {
				margin: 0;
				position: absolute;
				top: 33px;
				border-color: #8d1737;
				border-width: 1px;
				background-color: #fff;
				border-style: solid;
				color: #8d1737;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 100;
			}
		}

		.vr {
			height: 1px;
			position: absolute;
			bottom: 25px;
			margin: auto;
			border-bottom: 1px dashed gray;
			left: 0;
			right: 0;
			width: 50%;
		}

		.pagination_step.clicked {
			.step-name {
				color: #8d1737;
			}
			.step-number {
				background-color: #8d1737;
				color: #fff;
			}
		}
	}

	.form-wrapper {
		width: 500px;
		max-width: 95%;
		margin: 15px auto;
		margin-top: 30px;

		.error-message {
			color: red;
			direction: rtl;
			font-weight: 600;
			font-size: 1rem;
			line-height: 1.6;
			margin: 0;
		}

		.online-service {
			direction: rtl;
			color: #8d1737;
			cursor: pointer;
			width: fit-content;
			margin: auto 0 30px auto;
			display: block;
			user-select: none;
		}

		.input-wrapper {
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 17px 0;

			.multi-input {
				display: flex;
				box-sizing: border-box;
				overflow: hidden;
			}

			.input-label {
				position: absolute;
				top: 0;
				right: 15px;
				background-color: #fff;
				padding: 0px 10px;
				direction: rtl;
				color: #8d1737;
				font-size: 1.125rem;
				font-family: inherit;
			}

			.form-input {
				padding: 10px;
				padding-top: 16px;
				border: solid 1px #d2d2d2;
				background-color: #fff;
				direction: rtl;
				outline-color: #8d1737;
				outline-width: 1px;
				font-family: inherit;
				box-sizing: border-box;
				width: 100%;
			}
		}
		.form-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 30px;

			.form-button {
				background-color: #8d1737;
				border-radius: 30px;
				color: #fff;
				box-shadow: 0 5px 2.5px rgb(10 10 10 / 10%);
				padding: 0.75rem 3.4375rem;
				font-size: 1rem;
				border: none;
				outline: none;
				cursor: pointer;
				margin: 0px 5px;
				font-family: inherit;
				display: flex;
				align-items: center;
				justify-content: center;
				width: fit-content;
			}
		}
	}
}
